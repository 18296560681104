import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiUrl } from '../Api/ApiUrl';

const Main = () => {
    const [mains, setMain] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${ApiUrl}/cartoonapi.php`).then((response) => {
            var mains = response?.data?.data;
            setMain(mains);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <div className="container">
                {loading ? (
                    <div>
                        <p><b><center>Loading...</center></b></p>
                    </div>
                ) : (
                    <div className="h-600x h-sm-auto">
                        {mains.filter(main => main?.category_id === "1").length > 0 ? (
                            <>
                                <div className="h-2-3 h-sm-auto oflow-hidden">
                                    <div className="pb-5 pr-5 pr-sm-0 float-left float-sm-none w-2-3 w-sm-100 h-100 h-sm-300x">
                                        <a className="pos-relative h-100 dplay-block" href="#!">
                                            <img src={mains.filter(main => main?.category_id === "1").slice(-1)[0]?.image} className="img-fluid" alt="" />
                                            <div className="abs-blr color-white p-20 bg-sm-color-7">
                                                <h3 className="mb-15 mb-sm-5 font-sm-13">
                                                    <b>{mains.filter(main => main?.category_id === "1").slice(-1)[0]?.name}</b>
                                                </h3>
                                                <ul className="list-li-mr-20">
                                                    <li>
                                                        by{" "}
                                                        <span className="color-primary">
                                                            <b>{mains.filter(main => main?.category_id === "1").slice(-1)[0]?.postby}</b>
                                                        </span>{" "}
                                                        <br />
                                                        {new Date(mains.filter(main => main?.category_id === "1").slice(-1)[0]?.postdate).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' })}
                                                    </li>
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="float-left float-sm-none w-1-3 w-sm-100 h-100 h-sm-600x">
                                        <div className="pl-5 pb-5 pl-sm-0 ptb-sm-5 pos-relative h-50">
                                            <a className="pos-relative h-100 dplay-block" href="#!">
                                                <img src={mains.filter(main => main?.category_id === "1").slice(-2, -1)[0]?.image} className="img-fluid" alt="" style={{ marginTop: "-27px" }} />
                                                <div className="abs-blr color-white p-20 bg-sm-color-7">
                                                    <h4 className="mb-10 mb-sm-5">
                                                        <b>{mains.filter(main => main?.category_id === "1").slice(-2, -1)[0]?.name}</b>
                                                    </h4>
                                                    <ul className="list-li-mr-20">
                                                        <li>
                                                            by{" "}
                                                            <span className="color-primary">
                                                                <b>{mains.filter(main => main?.category_id === "1").slice(-2, -1)[0]?.postby}</b>
                                                            </span>{" "}
                                                            <br />
                                                            {new Date(mains.filter(main => main?.category_id === "1").slice(-2, -1)[0]?.postdate).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' })}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="pl-5 ptb-5 pl-sm-0 pos-relative h-50">
                                            <a className="pos-relative h-100 dplay-block" href="#!">
                                                <img src={mains.filter(main => main?.category_id === "1").slice(-3, -2)[0]?.image || 'images/no-image.png'} className="img-fluid" alt="" />
                                                <div className="abs-blr color-white p-20 bg-sm-color-7">
                                                    <h4 className="mb-10 mb-sm-5">
                                                        <b>{mains.filter(main => main?.category_id === "1").slice(-3, -2)[0]?.name || ''}</b>
                                                    </h4>
                                                    <ul className="list-li-mr-20">
                                                        <li>
                                                            by{" "}
                                                            <span className="color-primary">
                                                                <b>{mains.filter(main => main?.category_id === "1").slice(-3, -2)[0]?.postby || ''}</b>
                                                            </span>{" "}
                                                            <br />
                                                            {new Date(mains.filter(main => main?.category_id === "1").slice(-3, -2)[0]?.postdate).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' }) || ''}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="h-1-3 oflow-hidden">
                                    <div className="pr-5 pr-sm-0 pt-5 float-left float-sm-none pos-relative w-1-3 w-sm-100 h-100 h-sm-300x">
                                        <a className="pos-relative h-100 dplay-block" href="#!">
                                            <img src={mains.filter(main => main?.category_id === "1").slice(-4, -3)[0]?.image || 'images/no-image.png'} className="img-fluid" alt="" />
                                            <div className="abs-blr color-white p-20 bg-sm-color-7">
                                                <h4 className="mb-10 mb-sm-5">
                                                    <b>{mains.filter(main => main?.category_id === "1").slice(-4, -3)[0]?.name || ''}</b>
                                                </h4>
                                                <ul className="list-li-mr-20">
                                                    <li>
                                                        by{" "}
                                                        <span className="color-primary">
                                                            <b>{mains.filter(main => main?.category_id === "1").slice(-4, -3)[0]?.postby || ''}</b>
                                                        </span>{" "}
                                                        <br />
                                                        {new Date(mains.filter(main => main?.category_id === "1").slice(-4, -3)[0]?.postdate).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' }) || ''}
                                                    </li>
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="plr-5 plr-sm-0 pt-5 pt-sm-10 float-left float-sm-none pos-relative w-1-3 w-sm-100 h-100 h-sm-300x">
                                        <a className="pos-relative h-100 dplay-block" href="#!">
                                            <img src={mains.filter(main => main?.category_id === "1").slice(-5, -4)[0]?.image || 'images/no-image.png'} className="img-fluid" alt="" />
                                            <div className="abs-blr color-white p-20 bg-sm-color-7">
                                                <h4 className="mb-10 mb-sm-5">
                                                    <b>{mains.filter(main => main?.category_id === "1").slice(-5, -4)[0]?.name || ''}</b>
                                                </h4>
                                                <ul className="list-li-mr-20">
                                                    <li>
                                                        by{" "}
                                                        <span className="color-primary">
                                                            <b>{mains.filter(main => main?.category_id === "1").slice(-5, -4)[0]?.postby || ''}</b>
                                                        </span>{" "}
                                                        <br />
                                                        {new Date(mains.filter(main => main?.category_id === "1").slice(-5, -4)[0]?.postdate).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' }) || ''}
                                                    </li>
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="pl-5 pl-sm-0 pt-5 pt-sm-10 float-left float-sm-none pos-relative w-1-3 w-sm-100 h-100 h-sm-300x">
                                        <a className="pos-relative h-100 dplay-block" href="#!">
                                            <img src={mains.filter(main => main?.category_id === "1").slice(-6, -5)[0]?.image || 'images/no-image.png'} className="img-fluid" alt="" />
                                            <div className="abs-blr color-white p-20 bg-sm-color-7">
                                                <h4 className="mb-10 mb-sm-5">
                                                    <b>{mains.filter(main => main?.category_id === "1").slice(-6, -5)[0]?.name || ''}</b>
                                                </h4>
                                                <ul className="list-li-mr-20">
                                                    <li>
                                                        by{" "}
                                                        <span className="color-primary">
                                                            <b>{mains.filter(main => main?.category_id === "1").slice(-6, -5)[0]?.postby || ''}</b>
                                                        </span>{" "}
                                                        <br />
                                                        {new Date(mains.filter(main => main?.category_id === "1").slice(-6, -5)[0]?.postdate).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' }) || ''}
                                                    </li>
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <p className="text-center"><b>No Data Found</b></p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Main;
