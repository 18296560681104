import React from 'react'
import axios from 'axios'
import { ApiUrl } from '../Api/ApiUrl';
const Favourite = () => {
    const [loading, setLoading] = React.useState(true);
    const [posts, setPosts] = React.useState([]);

    React.useEffect(() => {
        axios.get(`${ApiUrl}/cartoonapi.php`).then((response) => {
            var posts = response?.data?.data;
            setPosts(posts);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-8">
                            <h4 className="p-title">
                                <b>FAVOURITE CARTOONS</b>
                            </h4>
                            <div className="row">
                                {loading && (
                                    <div className="col-sm-6" style={{ width: "100%" }}>
                                        <div className="skeleton" style={{ width: "100%" }}>
                                            <div className="skeleton-item" style={{ height: "300px" }}></div>
                                        </div>
                                    </div>
                                )}
                                {posts?.filter(post => post?.category_id ==="3")?.length ? (
                                    posts?.filter(post => post?.category_id ==="3")?.map((post) => (
                                        <div className="col-sm-6" key={post?.id}>
                                            <img src={post?.image || 'images/no-image.png'} alt="" style={{ width: "100%", height: "200px" }} />
                                            <h4 className="pt-20">
                                                <a href={() => false}>
                                                    <b>{post?.name}</b>
                                                </a>
                                            </h4>
                                            <ul className="list-li-mr-20">
                                                <li>
                                                    by{" "}
                                                    <span className="color-primary">
                                                        <b>{post?.postby}</b>
                                                    </span>{" "}
                                                    <br />
                                                    {post?.postdate} 
                                                </li>
                                            </ul>
                                            <br />
                                            <p>
                                                {post?.description}

                                            </p>
                                            <br />
                                            <p>
                                                <b>Season {post?.season || "0"} : Episodes {post?.episodes || "0"}</b>
                                            </p>
                                            <p>
                                                <b>Language : {post?.language}</b>
                                            </p>
                                            <p>
                                                <b>Quality : {post?.quality}</b>
                                            </p>
                                            <br />
                                            <a
                                                className="dplay-block btn-brdr-primary mt-20 mb-md-50"
                                                href={post.link || 'No Link Found'}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <b>CLICK TO DOWNLOAD</b>
                                            </a>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-center">No Data Found</p>
                                )}
                            </div>
                            <h4 className="p-title mt-30">
                                <b>FEATURED CARTOONS</b>
                            </h4>
                            <div className="row">
                                {posts?.filter(post => post?.category_id ==="2")?.length ? (
                                    posts?.filter(post => post?.category_id ==="2")?.map((post) => (
                                        <div className="col-sm-6">
                                            <img src={post?.image || 'images/no-image.png'} alt="" style={{ width: "100%", height: "200px" }} />
                                            <h4 className="pt-20">
                                                <a href={() => false}>
                                                    <b>{post?.name}</b>
                                                </a>
                                            </h4>
                                            <p>
                                                {post?.description}
                                            </p>
                                            <br />
                                            <a
                                                className="dplay-block btn-brdr-primary mt-20 mb-md-50"
                                                href={post.link || 'No Link Found'}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <b>CLICK TO VIEW</b>
                                            </a>
                                            <br />
                                            <ul className="list-li-mr-20">
                                                <li>
                                                    by{" "}
                                                    <span className="color-primary">
                                                        <b>{post?.postby}</b>
                                                    </span>{" "}
                                                    <br />
                                                    {post?.postdate} 
                                                </li>
                                            </ul>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-center">No Data Found</p>
                                )}
                            </div>
                        </div>
                        <div className="d-none d-md-block d-lg-none col-md-3" />
                        <div className="col-md-6 col-lg-4">
                            <div className="pl-20 pl-md-0">
                                <div className="mtb-50">
                                    <h4 className="p-title">
                                        <b>POPULAR CARTOONS</b>
                                    </h4>
                                    {posts?.filter(post => post?.category_id ==="4")?.length ? (
                                        posts?.filter(post => post?.category_id ==="4")?.map((post) => (
                                            <div className="mb-30" key={post?.id}>
                                                <a
                                                    className="pos-relative mb-20 dplay-block"
                                                    href={() => false}
                                                >
                                                    <div className="wh-100x abs-tlr">
                                                        <img src={post?.image || 'images/no-image.png'} alt="" />
                                                    </div>
                                                    <div className="ml-120 min-h-100x">
                                                        <h5>
                                                            <b>{post?.name}</b>
                                                        </h5>
                                                        <p>
                                                            <b>Language : {post?.language}</b>
                                                        </p>
                                                        <p>
                                                            <b>Quality : {post?.quality}</b>
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-center">No Data Found</p>
                                    )}
                                </div>
                                <div className="mtb-50 pos-relative">
                                    <img src="images/all.jfif" alt="" />
                                    <div className="abs-tblr bg-layer-7 text-center color-white">
                                        <div className="dplay-tbl">
                                            <div className="dplay-tbl-cell">
                                                <h4 style={{ marginTop: 70 }}>
                                                    <b>Available for mobile &amp; desktop</b>
                                                </h4>
                                                <a
                                                    className="mt-15 color-primary link-brdr-btm-primary"
                                                    href={() => false}
                                                >
                                                    <b>Download for free</b>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mtb-50 mb-md-0">
                                    <h4 className="p-title">
                                        <b>NEWSLETTER</b>
                                    </h4>
                                    <p className="mb-20">
                                        Subscribe to our newsletter to get notification about new updates,
                                        information, discount, etc..
                                    </p>
                                    <form className="nwsltr-primary-1" disabled>
                                        <input type="text" placeholder="Your email" disabled />
                                        <button type="submit" disabled>
                                            <i className="ion-ios-paperplane" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Favourite
