import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="bg-191 color-ccc">
                <div className="container">
                    <div className="pt-50 pb-20 pos-relative">
                        <div className="abs-tblr pt-50 z--1 text-center">
                            <div className="h-80 pos-relative">
                                <img className="opacty-1 h-100 w-auto" src="images/map.png" alt="nodata" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="color-ash text-white mb-0 text-center">
                                    Copyright © {new Date().getFullYear()} - Mr.Cartoon | All rights reserved | Developed By{" "}
                                    <i className="ion-heart" /> Sakthi
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="brdr-ash-1 opacty-2" />
                </div>
            </footer>

        </>
    )
}

export default Footer
