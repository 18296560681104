import React from 'react'

const Header = () => {
    return (
        <>
            <header>
                <div className="bg-191">
                    <div className="container">
                        <div className="oflow-hidden color-ash font-9 text-sm-center ptb-sm-5">
                            <ul className="float-right float-sm-none list-a-plr-10 list-a-plr-sm-5 list-a-ptb-15 list-a-ptb-sm-5">
                                <li>
                                    <a className="pl-0 pl-sm-10" href={() => false}>
                                        <i className="ion-social-facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href={() => false}>
                                        <i className="ion-social-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href={() => false}>
                                        <i className="ion-social-google" />
                                    </a>
                                </li>
                                <li>
                                    <a href={() => false}>
                                        <i className="ion-social-instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href={() => false}>
                                        <i className="ion-social-bitcoin" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <a className="logo" href="index.html">
                        <img src="images/logo.jpg" alt="Logo" />
                    </a>
                    <a className="right-area src-btn" href={() => false}>
                        <i className="active src-icn ion-search" />
                        <i className="close-icn ion-close" />
                    </a>
                    <div className="src-form">
                        <form>
                            <input type="text" placeholder="Search here" />
                            <button type="submit">
                                <i className="ion-search" />
                            </button>
                        </form>
                    </div>
                    <a className="menu-nav-icon" data-menu="#main-menu" href={() => false}>
                        <i className="ion-navicon" />
                    </a>
                    <ul className="main-menu" id="main-menu">
                        <li>
                            <a href={() => false}>HOME</a>
                        </li>
                        <li>
                            <a href={() => false}>
                                ABOUT US
                            </a>
                        </li>
                        <li>
                            <a href={() => false}>CARTOONS LIST</a>
                        </li>
                        <li>
                            <a href={() => false}>REQUEST COMMENTS</a>
                        </li>
                        <li>
                            <a href={() => false}>CONTACT US</a>
                        </li>
                    </ul>
                    <div className="clearfix" />
                </div>
            </header>

        </>
    )
}

export default Header
